// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery-mask-plugin')
require('datatables.net-buttons-bs4')
require( 'jszip' )
require( 'datatables.net-dt' )
require( 'datatables.net-buttons-dt' )
require( 'datatables.net-buttons/js/buttons.html5.js' )


import 'bootstrap'

import './global'
import './provider_profile'
import './time_table'
import './checkbox_array_input'
import './client_profile'
import './reports'
import './referral_events'

$.jMaskGlobals.watchDataMask = true;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
