document.addEventListener('turbolinks:load', () => {
    const $other = $('.checkbox-array-input .checkbox-option.other');
    $other.each(function() {
        const $this = $(this);
        const $otherCheckbox = $this.find('input[type="checkbox"]');
        const $otherTextField = $this.find('input[type="text"]');
        $otherCheckbox.on('change', function() {
            const checked = $(this).prop('checked');
            $otherTextField
                .toggleClass('hide', !checked)
                .attr('disabled', !checked);
        });

        // When the 'Other' textbox changes, updated the val of the checkbox
        $otherTextField.on('input', function() {
            $otherCheckbox.val($(this).val())
            
        });
    });
});
